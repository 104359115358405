import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';

function SocialIconsList() {
  const data = useStaticQuery(graphql`
    query {
      twitter: file(relativePath: { eq: "twitter-512.png" }) {
        childImageSharp {
          fixed(width: 16) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      linkedin: file(relativePath: { eq: "linkedin-512.png" }) {
        childImageSharp {
          fixed(width: 16) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="social">
      <ul className="animated fadeIn visible" data-animation="fadeIn" data-animation-delay="600">
        <li>
          <OutboundLink className="round-icons" href="https://twitter.com/jaydp17" target="_blank">
            <Img fixed={data.twitter.childImageSharp.fixed} alt="twitter icon" />
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            className="round-icons"
            href="https://in.linkedin.com/in/jaydp17"
            target="_blank"
          >
            <Img fixed={data.linkedin.childImageSharp.fixed} alt="linkedin icon" />
          </OutboundLink>
        </li>
      </ul>
    </div>
  );
}
export default SocialIconsList;
