import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';
import useHover from '../hooks/useHover';

interface Props {
  title: string;
  staticImage: string;
  gifImage: string;
  link: string;
}
function MenuItem({ title, staticImage, gifImage, link }: Props) {
  const [hoverRef, isHovered] = useHover();

  const content = (
    <div>
      <span className="foto">
        <img
          src={isHovered ? gifImage : staticImage}
          className="menu-img"
          alt="Menu-item-img"
          ref={hoverRef}
        />
      </span>
      <span className="name">{title}</span>
    </div>
  );
  if (link.startsWith('/'))
    return (
      <Link to={link} className="menu-li">
        {content}
      </Link>
    );
  return (
    <OutboundLink href={link} target="_blank" className="menu-li">
      {content}
    </OutboundLink>
  );
}

export default MenuItem;
