import React from 'react';
import profilePic from '../images/dp_1.jpg';

export default () => {
  return (
    <div className="col-xs-12 col-sm-5 col-lg-5 text-right">
      <div className="foto">
        <img src={profilePic} alt="Jaydeep dp" />
      </div>
    </div>
  );
};
