import React from 'react';
import ProfilePic from './ProfilePic';
import SocialIcons from './SocialIcons';

export default () => (
  <section className="home-header border-bottom padding-block">
    <div className="container">
      <div className="row">
        <ProfilePic />
        <div className="col-xs-12 col-sm-7 col-lg-7">
          <h1 className="title">Jaydeep Solanki</h1>
          <h3 className="sub-title">Full Stack Developer + DevOps</h3>
          <SocialIcons />
        </div>
      </div>
    </div>
  </section>
);
