import React from 'react';
import HomeHeader from '../components/HomeHeader';
import Layout from '../components/Layout';
import Menu from '../components/Menu';
import SEO from '../components/Seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Jaydeep Solanki" />
    <HomeHeader />
    <Menu />
  </Layout>
);

export default IndexPage;
