import React from 'react';
import aboutGif from '../images/about.gif';
import aboutStatic from '../images/about.png';
import contactGif from '../images/contact.gif';
import contactStatic from '../images/contact.png';
import githubGif from '../images/github.gif';
import githubStatic from '../images/github.png';
import resumeGif from '../images/resume.gif';
import resumeStatic from '../images/resume.png';
import MenuItem from './MenuItem';

export default function Menu() {
  return (
    <nav className="menu">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-12">
            <MenuItem
              title={'About Me'}
              staticImage={aboutStatic}
              gifImage={aboutGif}
              link={'/me'}
            />
            <MenuItem
              title={'Resume'}
              staticImage={resumeStatic}
              gifImage={resumeGif}
              link={'/resume'}
            />
            <MenuItem
              title={'Github'}
              staticImage={githubStatic}
              gifImage={githubGif}
              link={'https://github.com/jaydp17'}
            />
            <MenuItem
              title={'Contact'}
              staticImage={contactStatic}
              gifImage={contactGif}
              link={'mailto:jaydp17@gmail.com'}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
